import React, {useContext} from "react";
import { Button } from "@material-ui/core";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {RegisterTypesEnum} from "core/enums";
import {UtilsContext} from "../../../../core/context/utils.context";
import FacebookIcon from '@material-ui/icons/Facebook';

type FacebookAuthProps = {
    acceptTerms?: boolean;
    onSuccess: (data: any) => void;
}

export const FacebookAuth: React.FC<FacebookAuthProps> = ({onSuccess, acceptTerms}) => {

    const utilsContext = useContext(UtilsContext);
    const onSubmit = (data: any): void => {

        if(data?.status === 'unknown') {
            return;
        }
        
        if(acceptTerms === false) {
            utilsContext.addSnackbar('error', 'Please accept the terms and conditions');
            return;
        }

        let name = {
            firstName: '',
            lastName: '',
        }
        if(data.name?.indexOf(' ') > -1) {
            name = {
                firstName: data.name.split(' ')[0],
                lastName: data.name.split(' ')[1],
            }
        } else {
            name.firstName = data.name;
            name.lastName = data.name;
        }

        const payload = {
            firstName: name.firstName,
            lastName: name.lastName,
            email: data.email,
            token: data.accessToken,
            termsAccepted: true,
            externalId: data.userID,
            registerType: RegisterTypesEnum.Facebook,
        }
        onSuccess(payload);
    }

    return (
        <>
            <FacebookLogin
                isDisabled={false}
                appId="1082555370267534"
                autoLoad={false}
                fields="email, name, id"
                scope="public_profile, email"
                callback={(resp: any) => onSubmit(resp)}
                render={(renderProps: any) => (
                    <Button
                        id="continue-with-facebook-btn"
                        variant="contained"
                        className="external-auth-btn"
                        onClick={() => renderProps.onClick()}
                    >
                        <FacebookIcon />
                    </Button>
                )}
            />
        </>

    )
}
